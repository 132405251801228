import React, { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  calculateReturnDate,
  countLeaveDays,
  createLeavePlan,
  getHolidaysInPeriod,
  getMyAnnualLeavePlan,
  getMySupervisor,
  getPublicHolidays,
} from "../../../store/leave/actions";
import { connect } from "react-redux";
import moment from "moment";

const PlanAnnualLeaveForm = (props) => {
  const {
    allPlannedDays,
    showCreateAnnualLeave,
    setShowCreateAnnualLeave,
    selectedPlan,
    leaveToPostpone,
    loading,
    user,
    publicHolidays,
    countLeaveDays,
    createLeavePlan,
    getMyAnnualLeavePlan,
    myLeavePlans,
    getPublicHolidays,
    calculateReturnDate,
    getHolidaysInPeriod,
    getMySupervisor,
  } = props;
  const [leaveDateRange, setLeaveDateRange] = useState({
    startDate: null,
    endDate: null,
    effectiveDate: null,
  });
  const [countingDays, setCountingDays] = useState(false);
  const [plannedDays, setPlannedDays] = useState(null);
  const [comment, setComment] = useState(selectedPlan?.employeeComment || "");
  const [errors, setErrors] = useState({
    startDateHasError: false,
    endDateHasError: false,
    effectiveDateHasError: false,
    messageHasError: false,
    messageErrorMessage: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [maxDate, setMaxDate] = useState(new Date(user?.RBM_fiscalYear?.endOn));
  const [loadingController, setLoadingController] = useState({
    supervisors: false,
    holidays: false,
  });
  const [holidaysDuringLeavePeriod, setHolidaysDuringLeavePeriod] = useState(
    []
  );
  const [supervisors, setSupervisors] = useState([]);

  useEffect(() => {
    if (showCreateAnnualLeave) {
      if (myLeavePlans.length === 0 && !leaveToPostpone) {
        getMyAnnualLeavePlan(setIsLoadingData);
      } else setIsLoadingData(false);
      if (selectedPlan) {
        setLeaveDateRange({
          startDate: new Date(selectedPlan?.startDate),
          endDate: new Date(selectedPlan?.endDate),
          effectiveDate: null,
        });
        setComment(selectedPlan?.employeeComment || "");
      } else if (leaveToPostpone) {
        setLeaveDateRange({
          startDate: new Date(leaveToPostpone.startDate),
          endDate: new Date(leaveToPostpone.endDate),
          effectiveDate: null,
        });
      }
      if (!supervisors.length) {
        getMySupervisor((loading, data) => {
          setLoadingController({ ...loadingController, supervisors: loading });
          if (!loading && data) {
            setSupervisors(data);
            setLoadingController({ ...loadingController, supervisors: false });
          }
        });
      }
    }

    if (publicHolidays.length === 0) getPublicHolidays(setIsLoadingData);

    return () => {
      setIsLoadingData(false);
    };
  }, [showCreateAnnualLeave]);

  useEffect(() => {
    if (!!leaveDateRange.startDate) {
      calculateReturnDate(
        {
          startDate: moment(leaveDateRange.startDate).format("YYYY-MM-DD"),
          numOfDays:
            user.leaveDays - allPlannedDays + (selectedPlan?.numOfDays || 0),
        },
        (loading, data) => {
          setCountingDays(loading);
          if (!loading && data) {
            if (
              !selectedPlan ||
              moment(leaveDateRange.startDate).format("YYYY-MM-DD") !==
                moment(selectedPlan?.startDate).format("YYYY-MM-DD")
            ) {
              setMaxDate(moment(data).add(1, "day").toDate());
            } else {
              setMaxDate(new Date(user?.RBM_fiscalYear?.endOn));
            }
          }
        }
      );
    } else setMaxDate(new Date(user?.RBM_fiscalYear?.endOn));
  }, [leaveDateRange.startDate]);

  useEffect(() => {
    if (!!leaveDateRange.startDate && !!leaveDateRange.endDate) {
      countLeaveDays(
        {
          startDate: moment(leaveDateRange.startDate).format("YYYY-MM-DD"),
          endDate: moment(leaveDateRange.endDate).format("YYYY-MM-DD"),
          leavePlanId: selectedPlan?.id || leaveToPostpone?.id || "",
          isPostponed: !!leaveToPostpone,
        },
        (loading, data) => {
          setCountingDays(loading);
          if (!loading) setPlannedDays(data);
        }
      );
    }

    if (!!!!leaveDateRange.endDate && !holidaysDuringLeavePeriod[0]) {
      getHolidaysInPeriod(
        {
          startDate: moment(leaveDateRange.startDate).format("YYYY-MM-DD"),
          endDate: moment(leaveDateRange.endDate).format("YYYY-MM-DD"),
        },
        (loading, data) => {
          setLoadingController({ ...loadingController, holidays: loading });
          if (!loading && data) {
            setHolidaysDuringLeavePeriod(data);
            setLoadingController({ ...loadingController, holidays: false });
          }
        }
      );
    }
  }, [leaveDateRange.endDate]);

  const onClose = () => {
    // clean all states before close
    setLeaveDateRange({
      startDate: null,
      endDate: null,
      effectiveDate: null,
    });
    setPlannedDays(null);
    setComment("");
    setErrors({
      startDateHasError: false,
      endDateHasError: false,
      messageHasError: false,
      messageErrorMessage: "",
    });
    setIsSubmitting(false);
    setShowCreateAnnualLeave();
  };

  useEffect(() => {
    if (!!leaveToPostpone) {
      const endOfYear = moment(user?.RBM_fiscalYear?.endOn).endOf("year");
      setMaxDate(endOfYear.toDate());
    } else {
      setMaxDate(new Date(user?.RBM_fiscalYear?.endOn));
    }

    return () => {
      setMaxDate(new Date(user?.RBM_fiscalYear?.endOn));
    };
  }, []);

  const onSubmitLeavePlan = () => {
    // handle validation
    let hasError = false;
    if (!leaveDateRange.startDate) {
      setErrors({
        ...errors,
        startDateHasError: true,
        messageErrorMessage: "Leave start date is required",
      });
      hasError = true;
    }
    if (!leaveDateRange.endDate) {
      setErrors({
        ...errors,
        endDateHasError: true,
        messageErrorMessage: "Leave end date is required",
      });
      hasError = true;
    }
    if (leaveDateRange.startDate > leaveDateRange.endDate) {
      setErrors({
        ...errors,
        endDateHasError: true,
        messageErrorMessage: "Leave end date cannot be less than start date",
      });
      hasError = true;
    }
    if (!!leaveToPostpone && !leaveDateRange.effectiveDate) {
      setErrors({
        ...errors,
        effectiveDateHasError: true,
        messageErrorMessage: "Effective date is required",
      });
      hasError = true;
    }
    if (!!leaveToPostpone && !comment) {
      setErrors({
        ...errors,
        messageHasError: true,
        messageErrorMessage: "Message is required",
      });
      hasError = true;
    }
    if (!!leaveToPostpone && comment.length < 10) {
      setErrors({
        ...errors,
        messageHasError: true,
        messageErrorMessage: "Message must be at least 10 characters",
      });
      hasError = true;
    }

    if (hasError) return;

    createLeavePlan(
      {
        startDate: moment(leaveDateRange.startDate).format("YYYY-MM-DD"),
        endDate: moment(leaveDateRange.endDate).format("YYYY-MM-DD"),
        comment,
        leavePlanId: selectedPlan?.id || leaveToPostpone?.id || "",
        effectiveDate: leaveDateRange.effectiveDate,
        previousPostponedLeaveId: leaveToPostpone?.postponedId || null,
        isForPostponement: !!leaveToPostpone,
      },
      (loading, res) => {
        setIsSubmitting(loading);
        if (!loading && res) {
          onClose();
        }
      }
    );
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const isHoliday = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    if (
      moment(formattedDate).isSameOrBefore(
        user.currentEmployeePosition.oneYearAfterEmployment
      )
    )
      return true;
    return publicHolidays.some(
      (holiday) => moment(holiday.date).format("YYYY-MM-DD") === formattedDate
    );
  };

  const wasTaken = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return myLeavePlans
      .filter((item) => item.statusId !== 7 && item.id !== selectedPlan?.id)
      .some((plan) => {
        const start = moment(plan.startDate).format("YYYY-MM-DD");
        const end = moment(plan.endDate).format("YYYY-MM-DD");
        return moment(formattedDate).isBetween(start, end, null, "[]");
      });
  };

  const handleDatesToDisable = (date) => {
    const thisDate = new Date(date);
    if (isWeekend(thisDate) && +user.leaveDays === 18) return true;
    if (isHoliday(thisDate) && +user.leaveDays === 18) return true;
    if (wasTaken(thisDate)) return true;
    return false;
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showCreateAnnualLeave}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className="text-dark pb-2">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "90%" }}
        >
          {!!selectedPlan
            ? "UPDATE"
            : !!leaveToPostpone
            ? "POSTPONE"
            : "CREATE"}{" "}
          ANNUAL LEAVE |{" "}
          <strong className="text-primary">{user?.RBM_fiscalYear?.name}</strong>
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent className="py-1">
        <div className="row">
          {/* leave form */}
          <div className="col-12 col-sm-6">
            {moment(user?.RBM_fiscalYear?.startOn).isSameOrBefore(
              user.currentEmployeePosition.oneYearAfterEmployment
            ) && (
              <Alert
                variant="outlined"
                severity="warning"
                color="info"
                className="mb-3"
              >
                Your leave plan cannot start before{" "}
                <b>
                  {moment(
                    user.currentEmployeePosition.oneYearAfterEmployment
                  ).format("LL")}
                </b>
              </Alert>
            )}
            {!!plannedDays &&
              (plannedDays.days > plannedDays.allowedDays ? (
                <Alert variant="standard" severity="error" className="mb-3">
                  You have exceeded the allowed days of leave
                </Alert>
              ) : !!plannedDays.overlapWithOtherLeavePlans ? (
                <Alert
                  variant="standard"
                  severity="warning"
                  color="error"
                  className="mb-3"
                >
                  This leave plan overlaps with other leave plans you have
                  created
                </Alert>
              ) : !!plannedDays.otherEmployeesWithLeave ? (
                <Alert
                  variant="standard"
                  severity="success"
                  color="warning"
                  className="mb-3"
                >
                  Other <b>{plannedDays.otherEmployeesWithLeave}</b> staff
                  {plannedDays.otherEmployeesWithLeave > 1
                    ? "s have"
                    : " has"}{" "}
                  planned leave for the same period
                </Alert>
              ) : null)}
            {!!errors.effectiveDateHasError && (
              <Alert variant="standard" severity="error">
                {errors.messageErrorMessage}
              </Alert>
            )}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="d-flex align-items-center mt-1">
                <DesktopDatePicker
                  label="Date to start leave"
                  inputFormat="dd/MM/yyyy"
                  disabled={
                    loading || countingDays || isSubmitting || isLoadingData
                  }
                  value={leaveDateRange.startDate}
                  onChange={(date) => {
                    setLeaveDateRange({
                      ...leaveDateRange,
                      startDate: date,
                      endDate: null,
                    });
                    setPlannedDays(null);

                    setErrors({
                      ...errors,
                      startDateHasError: false,
                    });
                  }}
                  defaultCalendarMonth={
                    new Date(
                      selectedPlan?.createdOn ||
                        (moment(user?.RBM_fiscalYear?.startOn).isAfter(
                          user.currentEmployeePosition.oneYearAfterEmployment
                        )
                          ? new Date(user?.currentDate)
                          : user.currentEmployeePosition.oneYearAfterEmployment)
                    )
                  }
                  minDate={
                    new Date(
                      selectedPlan?.createdOn ||
                        (moment(user?.RBM_fiscalYear?.startOn).isAfter(
                          user.currentEmployeePosition.oneYearAfterEmployment
                        )
                          ? user?.RBM_fiscalYear?.startOn
                          : user.currentEmployeePosition.oneYearAfterEmployment)
                    )
                  }
                  maxDate={new Date(user?.RBM_fiscalYear?.endOn)}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => e.preventDefault()}
                      required={true}
                      fullWidth
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      {...params}
                    />
                  )}
                  shouldDisableDate={handleDatesToDisable}
                  // disablePast={true}
                />

                <span className="mx-2" style={{ fontSize: "30px" }}>
                  -
                </span>

                <DesktopDatePicker
                  label="Date to end leave"
                  inputFormat="dd/MM/yyyy"
                  disabled={
                    !leaveDateRange.startDate ||
                    loading ||
                    countingDays ||
                    isSubmitting ||
                    isLoadingData
                  }
                  defaultCalendarMonth={
                    leaveDateRange.startDate || new Date(user?.currentDate)
                  }
                  value={leaveDateRange.endDate}
                  onChange={(date) => {
                    setLeaveDateRange({
                      ...leaveDateRange,
                      endDate: date,
                    });
                    setPlannedDays(null);

                    setErrors({
                      ...errors,
                      effectiveDateHasError: false,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => e.preventDefault()}
                      required={true}
                      fullWidth
                      size="small"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      {...params}
                    />
                  )}
                  minDate={leaveDateRange.startDate}
                  maxDate={maxDate}
                  shouldDisableDate={handleDatesToDisable}
                  // disablePast={!selectedPlan}
                />
              </div>

              {!!plannedDays && (
                <div className="d-flex align-items-center text-uppercase">
                  <span
                    className={`badge ${
                      plannedDays.days > 0 &&
                      plannedDays.days <= plannedDays.allowedDays
                        ? "badge-secondary"
                        : "badge-danger"
                    } text-left text-uppercase mr-1`}
                    style={{ minWidth: "132px" }}
                    title="Planned days of leave"
                  >
                    <span
                      className={`badge badge-light${
                        plannedDays.days > 0 &&
                        plannedDays.days <= plannedDays.allowedDays
                          ? " bg-secondary"
                          : " bg-danger"
                      } text-light`}
                      style={{ fontSize: "12px" }}
                    >
                      {plannedDays.days}/{plannedDays.allowedDays}
                    </span>{" "}
                    Planned days
                  </span>
                  <span
                    className="badge badge-warning text-left text-uppercase"
                    style={{ minWidth: "132px" }}
                    title="Other staff with same period of leave"
                  >
                    <span
                      className="badge badge-light bg-warning text-light"
                      // style={{ fontSize: "12px" }}
                    >
                      {plannedDays.otherEmployeesWithLeave}
                    </span>{" "}
                    Other staff
                  </span>
                </div>
              )}

              {!!leaveToPostpone && (
                <div className="d-flex align-items-center mt-3">
                  <DesktopDatePicker
                    label="Effective Date"
                    inputFormat="dd/MM/yyyy"
                    disabled={
                      loading || countingDays || isSubmitting || isLoadingData
                    }
                    value={leaveDateRange.effectiveDate}
                    onChange={(date) => {
                      setLeaveDateRange({
                        ...leaveDateRange,
                        effectiveDate: date,
                      });

                      setErrors({
                        ...errors,
                        effectiveDateHasError: false,
                      });
                    }}
                    minDate={
                      new Date(
                        selectedPlan?.createdOn || user?.RBM_fiscalYear?.startOn
                      )
                    }
                    maxDate={maxDate}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={(e) => e.preventDefault()}
                        required={true}
                        fullWidth
                        size="small"
                        {...params}
                      />
                    )}
                    shouldDisableDate={isWeekend}
                  />
                </div>
              )}
            </LocalizationProvider>

            <TextField
              error={errors.messageHasError}
              helperText={errors.messageErrorMessage}
              fullWidth
              size="small"
              multiline
              rows={4}
              // autoFocus={assignment.targetShare === 0}
              name="employeeMessage"
              label={`Message (${!!leaveToPostpone ? "required" : "optional"})`}
              variant="outlined"
              className="mb-3 mt-3"
              value={comment}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              onChange={(e) => {
                setComment(e.target.value);
                setErrors({
                  ...errors,
                  messageHasError: false,
                  messageErrorMessage: "",
                });
              }}
              disabled={
                loading ||
                countingDays ||
                !plannedDays ||
                isLoadingData ||
                isSubmitting
              }
            />
          </div>

          {/* leave information */}
          <div className="col-12 col-sm-6">
            <div>
              <span className="font-weight-bold">Response Manager*</span>
              {!loadingController.supervisors && !!supervisors.length ? (
                <Stack direction="row" spacing={1} overflow="auto">
                  {supervisors.map((item) => (
                    <Chip
                      avatar={<Avatar>{item.supervisorNames[0]}</Avatar>}
                      label={item.supervisorNames}
                      key={item.supervisorId}
                    />
                  ))}
                </Stack>
              ) : !loadingController.supervisors ? (
                <Alert severity="warning">
                  You don't have a supervisor, Please contact your HR!
                </Alert>
              ) : (
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: "1rem" }}
                />
              )}
            </div>

            {!!leaveDateRange.endDate && (
              <div
                className="mt-3 px-2 py-2"
                style={{
                  border: "0.12em solid #03a9f4",
                  borderRadius: "5px",
                }}
              >
                {!loadingController.holidays ? (
                  <div>
                    <span className="font-weight-bold">
                      Your request includes
                    </span>
                    {!!holidaysDuringLeavePeriod.length ? (
                      <div>
                        <div className="d-flex flex-column p-0 mt-2">
                          {holidaysDuringLeavePeriod.map((item, index) => (
                            <div className="m-0 mb-1" key={item.id}>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ fontSize: "15px" }}
                              >
                                <span>{item.name}</span>
                                <span
                                  key={item.id}
                                  style={{
                                    fontSize: "14px",
                                    color: "#0288d1",
                                    fontWeight: "400",
                                  }}
                                >
                                  {moment(item.date).format("LL")}
                                </span>
                              </div>
                              {holidaysDuringLeavePeriod.length !==
                                index + 1 && <Divider />}
                            </div>
                          ))}
                        </div>
                        <Divider className="my-3" />
                        <p
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#0288d1",
                          }}
                          className="ml-2 mt-2 mx-0"
                        >
                          <span
                            style={{
                              fontWeight: "600",
                            }}
                            className="mr-1"
                          >
                            Note:
                          </span>
                          {user?.leaveDays === 30
                            ? "Public holidays above were included in leave days, calendar days counted."
                            : "Public holidays above weren't included in leave days, only working days counted."}
                        </p>
                      </div>
                    ) : (
                      <div
                        style={{
                          fontSize: "12px",
                        }}
                        className="mt-2"
                      >
                        Your leave will not include any public holidays.
                      </div>
                    )}
                  </div>
                ) : (
                  <Box sx={{ minWidth: "100%" }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </Box>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <button
            onClick={onSubmitLeavePlan}
            type="button"
            className="btn btn-sm btn-primary text-uppercase px-5"
            disabled={
              loading ||
              countingDays ||
              !plannedDays ||
              plannedDays.days > plannedDays.allowedDays ||
              isLoadingData ||
              isSubmitting
            }
          >
            {isSubmitting ? "Wait..." : "Submit"}
          </button>
        </div>
      </DialogContent>
      <DialogActions className="py-2"></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ loading, user, myLeavePlans, publicHolidays }) => {
  return {
    loading,
    user,
    myLeavePlans,
    publicHolidays,
  };
};

export default connect(mapStateToProps, {
  countLeaveDays,
  createLeavePlan,
  getMyAnnualLeavePlan,
  getPublicHolidays,
  calculateReturnDate,
  getHolidaysInPeriod,
  getMySupervisor,
})(PlanAnnualLeaveForm);
